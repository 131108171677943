<template>
  <v-container fluid>
    <v-dialog v-model="onCreateBoat" max-width="400px" :fullscreen="!full">
      <new-boat ref="newBoat" @close="onCreateBoat = false" />
    </v-dialog>
    <v-dialog
      v-model="onCreateTournament"
      max-width="400px"
      :fullscreen="!full"
    >
      <new-tournament ref="newTournament" @close="onCreateTournament = false" />
    </v-dialog>
    <v-dialog v-model="onCreateSpecie" max-width="560px" :fullscreen="!full">
      <edit-item
        ref="editItem"
        @cancel="onCreateSpecie = false"
        @save="saveSpecie"
        :saving="saving"
      />
    </v-dialog>
    <v-form ref="tagForm" @submit="submit" :disabled="loading">
      <v-banner
        class="text-caption banner-note"
        two-line
        color="blue lighten-5"
        v-if="isRecapture && bannerNote"
      >
        Please fill out as much information as possible. At minimum, every
        recapture returned must have a species, date, location/GPS, and at least
        one member of the fishing team listed. If any of this information is
        missing, the report is not usable for research purposes
        <template v-slot:icon>
          <v-btn icon small @click="bannerNote = false">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-banner>
      <v-row>
        <v-col cols="12" sm="6" md="4" v-if="isRecapture">
          <v-text-field
            id="input-tagnumber"
            label="PRIOR TAG NUMBER"
            :rules="tagRule"
            v-mask="editMode ? null : 'BF#######'"
            v-model="priorNumber"
            outlined
            dense
            clearable
            hint="Enter the old tag number"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" :md="isRecapture ? 4 : 3">
          <v-text-field
            :label="tagNumberLabel"
            :rules="
              isRecapture
                ? recaptureRule.concat(noRepeatNumberRule)
                : rule.concat(tagRule)
            "
            v-model="number"
            v-mask="editMode ? null : 'BF#######'"
            outlined
            dense
            clearable
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" :md="isRecapture ? 4 : 3">
          <auto-complete
            label="OCEAN"
            :rules="rule"
            itemType="oceans"
            v-model="input.capture.ocean"
            details
          />
        </v-col>
        <v-col cols="12" sm="6" :md="isRecapture ? 6 : 3">
          <auto-complete
            ref="specieCombo"
            label="SPECIES"
            :rules="rule"
            itemType="species"
            v-model="input.capture.fish.specie"
            :ocean="input.capture.ocean"
            details
            add
            @new="launchCreateSpecieDialog"
          />
        </v-col>
        <v-col cols="12" sm="6" :md="isRecapture ? 6 : 3">
          <date-picker
            v-model="input.createdDate"
            :label="`${isRecapture ? 'RECAPTURE' : 'TAG'} DATE`"
            @clear="input.createdDate = null"
            :rules="rule"
          />
        </v-col>
      </v-row>

      <coordinates-form
        v-model="input.coordinates"
        ref="coordsForm"
        @change="setMarker"
        previous-locations
        @activatePreviousLocations="
          (value) => (previousLocationsActive = value)
        "
      />

      <v-row>
        <v-col cols="12">
          <fishing-locations
            ref="fishingLocations"
            @coords="setCoords"
            @validate="notifyInvalidCoords"
            draggable
            :input="input"
          />
        </v-col>
        <v-col cols="12">
          <auto-complete-location
            label="LOCATION NAME"
            v-model="input.coordinates"
            @ocean="(value) => (input.capture.ocean = value)"
            addNew
            :rules="rule"
          />
          <!-- @locate="(coords) => (!validCoordinates ? setMarker(coords) : null)" -->
          <!-- :coordinates="input.coordinates" -->
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <h3 class="font-weight-medium">Fish Length</h3>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            id="input-fishlength"
            v-model="input.capture.fish.length.value"
            label="VALUE"
            v-mask="'#######'"
            maxLength="7"
            outlined
            dense
            clearable
            :rules="lengthRule"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-autocomplete
            v-model="input.capture.fish.length.unit"
            label="UNIT"
            :items="length.units"
            outlined
            dense
            clearable
            auto-select-first
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-autocomplete
            v-model="input.capture.fish.length.type"
            label="TYPE"
            :items="length.types"
            outlined
            dense
            clearable
            auto-select-first
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-autocomplete
            v-model="input.capture.fish.length.determination"
            label="DETERMINATION"
            :items="determinations"
            outlined
            dense
            clearable
            auto-select-first
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <h3 class="font-weight-medium">Fish Weight</h3>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            id="input-fishweight"
            v-model="input.capture.fish.weight.value"
            label="VALUE"
            v-mask="'#######'"
            maxLength="7"
            outlined
            dense
            clearable
            :rules="weightRule"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-autocomplete
            v-model="input.capture.fish.weight.unit"
            label="UNIT"
            :items="weight.units"
            outlined
            dense
            clearable
            auto-select-first
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-autocomplete
            v-model="input.capture.fish.weight.type"
            label="TYPE"
            :items="weight.types"
            outlined
            dense
            clearable
            auto-select-first
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-autocomplete
            v-model="input.capture.fish.weight.determination"
            label="DETERMINATION"
            :items="determinations"
            outlined
            dense
            clearable
            auto-select-first
          ></v-autocomplete>
        </v-col>
        <v-col cols="12">
          <h3 class="font-weight-medium">Fight Time</h3>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            id="input-fighttime-hours"
            v-model="input.capture.fish.fight.hours"
            label="HOURS"
            v-mask="'###'"
            min="0"
            max="59"
            maxLength="7"
            outlined
            dense
            clearable
            type="number"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            id="input-fighttime-minutes"
            v-model="input.capture.fish.fight.minutes"
            label="MINUTES"
            v-mask="'##'"
            min="0"
            max="59"
            maxLength="7"
            outlined
            dense
            clearable
            type="number"
            :rules="timeRule"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6">
          <v-switch
            inset
            label="Shark Encounter"
            v-model="input.sharkEncounter"
            hide-details
            v-if="input.sharkEncounter !== null"
          ></v-switch>
          <h4 class="mt-2" v-else>Shark Encounter: N/A</h4>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="4">
          <auto-complete
            label="FISH CONDITION"
            itemType="conditions"
            v-model="input.capture.fish.condition"
            :isRecapture="isRecapture"
          />
        </v-col>
        <v-col cols="12" sm="4">
          <auto-complete
            label="BAIT TYPE"
            itemType="baits"
            v-model="input.capture.bait"
          />
        </v-col>
        <v-col cols="12" sm="4">
          <auto-complete
            label="HOOK TYPE"
            itemType="hooks"
            v-model="input.capture.hook"
          />
          <v-checkbox
            v-model="input.capture.hook.isRemoved"
            hide-details
            label="Hook Removed"
            class="shrink mr-2 mt-0"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-textarea
            auto-grow
            rows="1"
            label="NOTES"
            v-model="input.capture.notes"
            outlined
            clearable
            dense
            counter
            :rules="notesRule"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <auto-complete
            label="GEAR TYPE"
            itemType="gears"
            v-model="input.capture.gear"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <auto-complete
            label="TOURNAMENT"
            itemType="tournaments"
            v-model="input.capture.tournament"
            :rules="tournamentRule"
            add
            @new="createTournament"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-end">
          <v-bottom-sheet
            v-model="sheet"
            persistent
            :fullscreen="!full"
            :scrollable="!full"
          >
            <template v-slot:activator="{ attrs, on }">
              <v-btn small color="primary" v-bind="attrs" v-on="on">
                Add New User
              </v-btn>
            </template>
            <new-user
              ref="newUser"
              @close="closeEditMode"
              @added="newUserAdded"
            />
          </v-bottom-sheet>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <auto-complete-users
            label="ANGLER"
            v-model="input.angler"
            addNew
            @add="editUser(input.angler)"
            @edit="editUser(input.angler)"
            editable
            :rules="[(v) => !v?.includes('@') || 'Required']"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <auto-complete-users
            label="CAPTAIN"
            v-model="input.captain"
            addNew
            @add="editUser(input.captain)"
            @edit="editUser(input.captain)"
            editable
            :rules="[(v) => !v?.includes('@') || 'Required']"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="0" sm="3" />
        <v-col cols="12" sm="6">
          <auto-complete
            label="BOAT NAME"
            itemType="boats"
            v-model="input.boat"
            @new="createBoat"
            add
            :rules="boatRule"
          />
        </v-col>
        <v-col cols="0" sm="3" />
      </v-row>
      <v-row>
        <v-col cols="12" sm="6">
          <auto-complete-users
            label="FIRST MATE"
            v-model="input.firstMate"
            addNew
            @add="editUser(input.firstMate)"
            @edit="editUser(input.firstMate)"
            editable
            :rules="[(v) => !v?.includes('@') || 'Required']"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <auto-complete-users
            label="SECOND MATE"
            v-model="input.secondMate"
            addNew
            @add="editUser(input.secondMate)"
            @edit="editUser(input.secondMate)"
            editable
            :rules="[(v) => !v?.includes('@') || 'Required']"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import AutoComplete from '../../../../components/appComponents/AutoComplete.vue';
import AutoCompleteUsers from '../../../../components/appComponents/AutoCompleteUsers.vue';
import {
  DETERMINATIONS,
  getUserInfo,
  LENGTH_TYPES,
  LENGTH_UNITS,
  notifyMessage,
  setRequestedUserData,
  WEIGHT_TYPES,
  WEIGHT_UNITS,
  getLengthLimitRules,
  getWeightLimitRules
} from '../../../../helpers/handler';
import {
  CaptureViewModel,
  CoordinatesViewModel,
  CrewViewModel
} from '../../viewmodels';
import NewBoat from './NewBoat.vue';
import NewTournament from './NewTournament.vue';
import NewUser from './NewUser.vue';
import FishingLocations from '../../../map/FishingLocations.vue';
import DatePicker from '../../../../components/appComponents/DatePicker.vue';
import AutoCompleteLocation from '../../../../components/appComponents/AutoCompleteLocation.vue';
import CoordinatesForm from './CoordinatesForm.vue';
import EditItem from './EditItem.vue';
import moment from 'moment';
export default {
  name: 'tag-form',
  components: {
    AutoCompleteUsers,
    AutoComplete,
    NewUser,
    NewBoat,
    NewTournament,
    FishingLocations,
    DatePicker,
    AutoCompleteLocation,
    CoordinatesForm,
    EditItem
  },
  props: {
    fillAngler: {
      type: Boolean,
      default: false
    },
    isRecapture: {
      type: Boolean,
      default: false
    },
    record: {
      type: Object,
      default: null
    }
  },
  computed: {
    full() {
      return !this.$vuetify.breakpoint.smAndDown;
    },
    number: {
      get: function () {
        return this.input.number;
      },
      set: function (v) {
        this.input.number =
          !v || v === 'BF' ? null : String(v).includes('BF') ? v : `BF${v}`;
      }
    },
    priorNumber: {
      get: function () {
        return this.input.priorTag.priorTagNumber;
      },
      set: function (v) {
        this.input.priorTag.priorTagNumber =
          !v || v === 'BF' ? null : String(v).includes('BF') ? v : `BF${v}`;
        this.input.priorTag.havePriorTag =
          !!this.input.priorTag.priorTagNumber?.length;
      }
    },
    rulePriorTag() {
      return [() => this.input?.priorTag?.havePriorTag || 'Required'];
    },
    lengthRule() {
      const { specie, length } = this.input?.capture?.fish || {};
      const { value, unit } = length || {};
      const { acronym } = specie || {};
      const rule = getLengthLimitRules(acronym, value, unit);
      return rule;
    },
    weightRule() {
      const { specie, weight } = this.input?.capture?.fish || {};
      const { value, unit } = weight || {};
      const { acronym } = specie || {};
      const rule = getWeightLimitRules(acronym, value, unit);
      return rule;
    },
    validCoordinates() {
      if (this.isRecapture) {
        return true;
      }
      return this.$refs.coordsForm && this.$refs.coordsForm.validate();
    },
    tagNumberLabel() {
      return this.isRecapture ? 'NEW TAG NUMBER (optional)' : 'TAG NUMBER';
    },
    noRepeatNumberRule() {
      return [
        (v) =>
          !v || v !== this.priorNumber || 'number already used as prior tag'
      ];
    },
    boatRule() {
      const { boat } = this.input || {};
      return [
        (value) => {
          if (boat?.name && !value?.id) {
            return 'Required';
          }

          return true;
        }
      ];
    },
    tournamentRule() {
      const { tournament } = this.input?.capture || {};
      return [
        (value) => {
          if (tournament?.name && !value?.id) {
            return 'Required';
          }

          return true;
        }
      ];
    },
    tagRule() {
      return [
        (v) => {
          if (this.isRecapture && !v) {
            return true;
          }

          if (!v) {
            return 'Required';
          }

          if (v.length < 8) {
            return 'Minimum 6 digits required';
          }

          return true;
        }
      ];
    },
    rule() {
      return [
        (v) => {
          if (this.isRecapture && !v) {
            return true;
          }

          if (!v) {
            return 'Required';
          }

          return true;
        }
      ];
    }
  },
  data: () => ({
    input: window.structuredClone(initInput),
    onCreateBoat: false,
    onCreateTournament: false,
    onCreateSpecie: false,
    sheet: false,
    editUserId: null,
    loading: false,
    saving: false,
    // rule: [(v) => !!v || 'Required'],
    determinations: DETERMINATIONS,
    weight: {
      units: Object.values(WEIGHT_UNITS),
      types: WEIGHT_TYPES
    },
    length: {
      units: Object.values(LENGTH_UNITS),
      types: LENGTH_TYPES
    },
    timeRule: [(v) => (v <= 59 && v >= 0) || '0 - 59 values allowed'],
    // tagRule: [(v) => v?.length >= 8 || 'Minimum 6 digits required'],
    recaptureRule: [(v) => !v || v?.length >= 8 || 'Minimum 6 digits required'],
    notesRule: [(v) => !v || v?.length <= 300 || 'Max 300 characters'],
    invalidCoords: false,
    editMode: false,
    prevUserList: [],
    previousLocationsActive: false,
    bannerNote: true
  }),
  methods: {
    ...mapActions('users', ['getUser']),
    ...mapActions('entry', ['addItem']),
    async initRecord(record) {
      this.$refs.tagForm && (this.$refs.tagForm.scrollTop = 0);
      this.loading = true;
      this.resetAll();
      if (!record) {
        if (this.fillAngler) {
          const userInfo = getUserInfo();
          const { data } = (await this.getUser({ id: userInfo?.id })) || {};
          if (data) {
            this.input.angler = data;
          }
        }
        this.input['sharkEncounter'] = false;
      } else {
        this.input = { ...this.input, ...record };
        if (moment(this.input.enteredDate).isBefore(moment('10/01/2022'))) {
          if (!('sharkEncounter' in this.input)) {
            this.input['sharkEncounter'] = null;
          }
        } else {
          if (!this.input['sharkEncounter']) {
            this.input['sharkEncounter'] = false;
          }
        }
        this.editMode = true;
      }
      this.loading = false;
    },
    async submit() {
      if (!this.$refs.tagForm) {
        notifyMessage('Form validation failed');
        return;
      }

      const formValid = await this.$refs.tagForm.validate();
      let valid = formValid && this.validCoordinates;

      if (this.invalidCoords) {
        valid = null;
        this.notifyInvalidCoords(false);
      }
      const data = setRequestedUserData(this.input);
      const { errorBag, inputs } = this.$refs.tagForm;

      const errors = [];
      for (const key in errorBag) {
        if (!errorBag[key]) {
          continue;
        }

        const field = inputs.find(({ _uid }) => String(_uid) === key);

        if (!field) {
          continue;
        }

        errors.push(field.id);
      }

      if (!this.validCoordinates) {
        errors.push('form-coordinates');
      }

      return { valid, data, errors };
    },
    notifyInvalidCoords(isWater) {
      if (isWater) {
        this.invalidCoords = false;
        return;
      }
      this.invalidCoords = true;
      notifyMessage('Invalid coords, no seawater surface');
    },
    createBoat() {
      this.$refs.newBoat && this.$refs.newBoat.init();
      this.onCreateBoat = true;
    },
    createTournament() {
      this.$refs.newTournament && this.$refs.newTournament.init();
      this.onCreateTournament = true;
    },
    resetAll() {
      this.input = window.structuredClone(initInput);
      this.$refs.tagForm && this.$refs.tagForm.resetValidation();
    },
    resetInfo() {
      this.input.number = null;
      const { capture, sharkEncounter } = window.structuredClone(initInput);
      const { fish: captureFish, notes } = capture;
      this.input.capture.fish.fight = captureFish.fight;
      this.input.capture.fish.length = captureFish.length;
      this.input.capture.fish.weight = captureFish.weight;
      this.input.capture.fish.condition = captureFish.condition;
      this.input.capture.fish.specie = captureFish.specie;
      this.input.capture.notes = notes;
      this.input.sharkEncounter = sharkEncounter;
      this.$refs.tagForm && this.$refs.tagForm.resetValidation();
    },
    setCoords(coordinates) {
      if (!coordinates) {
        this.input.coordinates = { ...new CoordinatesViewModel().coordinates };
        return;
      }

      if (this.$refs.fishingLocations) {
        this.$refs.fishingLocations.getDescription(coordinates);
      }

      this.input.coordinates = { ...coordinates };
    },
    setBoatName(name) {
      if (name) {
        if (this.input?.boatName && !this.input?.boat?.name) {
          this.input.boat.name = this.input.boatName;
        }

        if (this.input?.boat?.name) {
          this.input.boatName = this.input.boat.name;
        }
      }
    },
    setMarker(coordinates = null) {
      if (!coordinates) {
        this.input.coordinates = { ...new CoordinatesViewModel().coordinates };
        return;
      }
      this.input.coordinates = { ...coordinates };
    },
    editUser(user = {}) {
      const { id, _id } = user || {};
      this.editUserId = id || _id;
      this.sheet = true;
    },
    closeEditMode() {
      this.sheet = false;
      this.editUserId = null;
    },
    async saveSpecie(data) {
      this.saving = true;
      const date = new Date();
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      const user = getUserInfo();
      const response = await this.addItem({
        item: 'species',
        data: {
          ...data,
          enteredBy: user?.email,
          enteredDate: date.toISOString(),
          updatedBy: user?.email,
          updatedDate: date.toISOString()
        }
      });
      this.saving = false;
      const { success } = response?.data || {};
      notifyMessage(
        success
          ? 'Successful operation'
          : 'Something went wrong, please try again'
      );
      if (success) {
        this.$refs.specieCombo &&
          (await this.$refs.specieCombo.getFilterItems());
      }
      this.onCreateSpecie = false;
    },
    launchCreateSpecieDialog() {
      this.onCreateSpecie = true;
      setTimeout(() => {
        this.$refs.editItem && this.$refs.editItem.initialize('species');
      }, 10);
    },
    initEditUser(value) {
      if (value) {
        setTimeout(async () => {
          if (this.$refs.newUser) {
            await this.$refs.newUser.init(this.editUserId);
          }
        }, 100);
      }
    },
    async setPreviousLocations(input, forceRender = false) {
      const { angler, captain, firstMate, secondMate } = input;
      const userList = [
        angler?._id,
        captain?._id,
        firstMate?._id,
        secondMate?._id
      ].filter(Boolean);

      if (!userList.length) {
        this.$refs.fishingLocations.clearLocationMarkers();
        return;
      }

      if (!forceRender && userList.length === this.prevUserList.length) {
        const newUsers = userList.filter(
          (id) => !this.prevUserList.includes(id)
        );
        if (!newUsers.length) {
          return;
        }
      }

      if (this.$refs.fishingLocations) {
        await this.$refs.fishingLocations.setLocationMarkers(userList);
        this.prevUserList = userList;
      }
    },

    newUserAdded(user) {
      const { email } = user || {};
      for (const crewPosition of [
        'angler',
        'captain',
        'firstMate',
        'secondMate'
      ]) {
        if (this.input[crewPosition].id === email) {
          this.input[crewPosition] = user;
        }
      }
      this.$emit('newUser', user);
    }
  },
  watch: {
    'input.boatName': {
      handler: 'setBoatName'
    },
    'input.boat.name': {
      handler: 'setBoatName'
    },
    sheet: {
      handler: 'initEditUser',
      immediate: true
    },
    input: {
      handler: async function (input) {
        if (!this.previousLocationsActive) {
          return;
        }

        await this.setPreviousLocations(input);
      },
      deep: true
    },
    previousLocationsActive: {
      handler: async function (value) {
        if (!value) {
          if (this.$refs.fishingLocations) {
            this.$refs.fishingLocations.clearLocationMarkers();
          }
          return;
        }

        await this.setPreviousLocations(this.input, value);
      },
      immediate: true
    },
    record: {
      handler: async function (record) {
        if (record) {
          await this.initRecord(window.structuredClone(record));
        }
      },
      immediate: true
    }
  }
};

const initInput = {
  ...new CrewViewModel(),
  ...new CaptureViewModel(),
  ...new CoordinatesViewModel(),
  ...{ boatName: null }
};
</script>

<style>
.v-input.v-input--switch--inset {
  margin-top: 4px;
}

.v-input.v-input--switch--inset .v-input--switch__track::after {
  content: 'No';
  color: #333333;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  margin-right: 5px;
  margin-top: 2px;
}

.v-input.v-input--switch--inset.v-input--is-label-active.v-input--is-dirty
  .v-input--switch__track::after {
  content: 'Yes';
  font-size: 8px;
  /* font-weight: bold; */
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  margin-left: 6px;
  margin-top: 3px;
  color: white;
}

.banner-note div[class~='v-banner__wrapper'] {
  padding: 0 1em !important;
  border-bottom: 0 !important;
  margin-bottom: 10px;
}

.banner-note div[class~='v-banner__content'] {
  display: flex;
  flex-direction: row-reverse;
  padding-left: 24px;
}

.banner-note div[class~='v-banner__icon'] {
  margin: 0 !important;
}
</style>
