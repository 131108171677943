<template>
  <v-card fluid>
    <v-toolbar flat dark color="primary" max-height="100px">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title> New </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn dark text @click="submit"> Save </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card-text>
      <v-form ref="tournamentForm" class="mt-5">
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Tournament Name"
              :rules="rule"
              v-model="input.name"
              hide-details
              outlined
              dense
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import { TournamentsViewModel } from '../../viewmodels/index';
import { notifyMessage } from '../../../../helpers/handler';
export default {
  name: 'new-tournament',
  data: () => ({
    input: new TournamentsViewModel(),
    rule: [(v) => !!v || 'Required']
  }),
  methods: {
    ...mapActions('entry', ['addItem']),
    async submit() {
      const valid =
        this.$refs.tournamentForm && this.$refs.tournamentForm.validate();
      if (valid) {
        const { name } = this.input;
        const response = await this.addItem({
          item: 'tournaments',
          data: { name, approved: true }
        });
        const { success } = response?.data || {};
        notifyMessage(
          success
            ? 'Tournament successfully created'
            : 'Tournament already exists or something went wrong'
        );
        this.$emit('close');
      }
    },
    init() {
      this.$refs.tournamentForm && this.$refs.tournamentForm.reset();
    }
  }
};
</script>
