<template>
  <v-card>
    <v-card-title> {{ title }} </v-card-title>
    <v-card-text>
      <v-form ref="editItemsForm">
        <div v-if="type === 'tournaments'">
          <v-text-field label="Name" v-model="item.name" clearable outlined />
        </div>
        <div v-if="type === 'boats'" class="pt-2">
          <v-text-field
            label="Name"
            v-model="item.name"
            :rules="rule"
            clearable
            outlined
            dense
          />
          <auto-complete-users
            label="Owner"
            v-model="item.owner"
            class="pb-7"
            :rules="rule"
          />
          <v-text-field
            label="Model"
            v-model="item.model"
            clearable
            outlined
            dense
          />
          <v-text-field
            label="Make"
            v-model="item.make"
            clearable
            outlined
            dense
          />
          <v-text-field
            label="Length"
            v-model="item.length"
            clearable
            outlined
            dense
          />
          <v-checkbox
            v-model="item.membership"
            label="Anglers receive certificate"
          ></v-checkbox>
        </div>
        <div v-if="type === 'species'">
          <v-row>
            <v-col cols="12" sm="9">
              <v-text-field label="Name" v-model="item.name" clearable />
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="Acronym" v-model="item.acronym" clearable />
            </v-col>
          </v-row>
          <v-checkbox label="non BillFish" v-model="item.nonBillFish" />
        </div>
        <div v-if="type === 'baits'">
          <v-row>
            <v-col cols="12" sm="9">
              <v-text-field label="Name" v-model="item.name" clearable />
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="Acronym" v-model="item.acronym" clearable />
            </v-col>
          </v-row>
        </div>
        <div v-if="type === 'gears'">
          <v-row>
            <v-col cols="12" sm="9">
              <v-text-field label="Name" v-model="item.name" clearable />
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="Acronym" v-model="item.acronym" clearable />
            </v-col>
          </v-row>
        </div>
        <div v-if="type === 'trophies'">
          <v-text-field label="Name" v-model="item.name" clearable />
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Fisher's name"
                v-model="item.fisherName"
                clearable
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Fisher's role"
                v-model="item.role"
                clearable
              />
            </v-col>
          </v-row>
        </div>
        <div v-if="type === 'locations'">
          <new-location
            ref="newLocation"
            v-model="item"
            @location="setLocation"
            onlyForm
          />
        </div>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="cancel" :disabled="saving">
        Cancel
      </v-btn>
      <v-btn color="primary" text @click="save" :disabled="saving">
        {{ saving ? 'Saving...' : 'Save' }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import {
  TournamentsViewModel,
  BoatsViewModel,
  SpeciesViewModel,
  BaitsViewModel,
  GearsViewModel,
  TrophiesViewModel,
  LocationsViewModel
} from '../../viewmodels/index';
import AutoCompleteUsers from '../../../../components/appComponents/AutoCompleteUsers.vue';
import NewLocation from './NewLocation.vue';

export default {
  components: {
    AutoCompleteUsers,
    NewLocation
  },
  name: 'edit-item',
  props: {
    type: {
      type: String,
      default: 'tournaments'
    },
    saving: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    item: {},
    title: 'Add Value',
    oceans: [],
    ocean: null,
    rule: [(v) => !!v || 'Required']
  }),
  mounted() {
    this.saving = false;
  },
  computed: {
    model() {
      switch (this.type) {
        case 'boats':
          return BoatsViewModel;

        case 'species':
          return SpeciesViewModel;

        case 'baits':
          return BaitsViewModel;

        case 'gears':
          return GearsViewModel;

        case 'trophies':
          return TrophiesViewModel;

        case 'locations':
          return LocationsViewModel;

        case 'tournaments':
        default:
          return TournamentsViewModel;
      }
    }
  },
  watch: {
    model: {
      handler: 'initForm'
    }
  },
  methods: {
    ...mapActions('query', ['getItems']),
    cancel() {
      this.$emit('cancel');
    },
    save() {
      if (this.type === 'locations') {
        const valid =
          this.$refs.newLocation && this.$refs.newLocation.validateForm();
        if (valid) {
          this.item['approved'] = true;
          this.$emit('save', this.item);
        }
        return;
      }
      const valid =
        this.$refs.editItemsForm && this.$refs.editItemsForm.validate();
      if (valid) {
        if (this.item['owner']) {
          const { firstName, lastName } = this.item['owner'];
          this.item['owner'] = { firstName, lastName };
        }
        if (this.type === 'tournaments') {
          this.item['approved'] = true;
        }
        this.$emit('save', this.item);
      }
    },
    setLocation(value) {
      this.item = value;
      this.$emit('cancel');
    },
    initForm(model) {
      this.item = new model();
    },
    initialize(data = null) {
      if (data) {
        data?._id && (this.title = 'Edit Value');
        this.item = new this.model().init(data);
      }
    }
  }
};
</script>
